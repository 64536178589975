.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress-bar {
  width: 5em;
}
.progress-segment {
  display:inline-block;
  background-color: #ddd;
  width: calc(20% - 0.25em);
  margin: 0 0.125em;
  height: 0.5em;
}
.progress-bar.val-1 > .progress-segment:nth-child(-n+1),
.progress-bar.val-2 > .progress-segment:nth-child(-n+2),
.progress-bar.val-3 > .progress-segment:nth-child(-n+3),
.progress-bar.val-4 > .progress-segment:nth-child(-n+4) {
  background-color: green;
}

.progress-bar.multicolor.val-3 > .progress-segment:nth-child(3),
.progress-bar.multicolor.val-4 > .progress-segment:nth-child(3){
  background-color: rgb(248, 211, 0);
}
.progress-bar.multicolor.val-4 > .progress-segment:nth-child(4) {
  background-color: rgb(223, 83, 2);
}
.progress-bar.multicolor.val-3 > .progress-segment:nth-child(3), 
.progress-bar.multicolor.val-4 > .progress-segment:nth-child(3){ 
    background-color: rgb(248, 211, 0);
}
.progress-bar.multicolor.val-4 > .progress-segment:nth-child(4) { 
    background-color: rgb(223, 83, 2);
}

.app-footer {
  border-top: 1px solid #eee;
  padding: 1em 0 2em 1em;
  font-size: 80%;
}
.app-footer * {
  color: #999;
  margin: 0 1em;
}

.tab-with-two-columns-left {
  width: 40%;
  float: left;
}
